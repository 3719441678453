// libs
import _ from 'lodash'
import useSWR, { KeyedMutator } from 'swr'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'
// queries
import { GET_USERS, GET_USERS_AGGREGATE, UsersAggregateResponse, UsersResponse } from 'queries'
// types
import { Users } from 'types/hasura'
// providers
import { AppSwrConfigContext } from '../SwrConfig'

interface Props {
  children: ReactNode
}

interface Context {
  users: Users[] | undefined
  mutate: KeyedMutator<UsersResponse>
  perPage: number
  sortUsers: (sortBy: string) => void
  totalUsers: number
  fetchUsers: (page: number, direction: 'asc' | 'desc') => Promise<void>
  searchUsers: (input: string) => void
}

export const AdminUsersApiContext = createContext<Context>({} as any)

export function AdminUsersApiProvider({ children }: Props): JSX.Element {
  const { request } = useContext(AppSwrConfigContext)

  const router = useRouter()

  const perPage = 20
  const initialPage = Number(router.query.page) || 1

  const { data } = useSWR<UsersAggregateResponse>(GET_USERS_AGGREGATE, { revalidateOnFocus: false })

  const { data: usersData, mutate } = useSWR<UsersResponse>(
    [
      GET_USERS,
      {
        limit: perPage,
        offset: (initialPage - 1) * perPage,
      },
    ],
    { revalidateOnFocus: false, revalidateIfStale: true },
  )

  const totalUsers = data?.usersAggregate?.aggregate?.count ?? 0

  const fetchUsers: Context['fetchUsers'] = useCallback(
    async (page, direction) => {
      try {
        const variables = {
          limit: perPage,
          offset: (page - 1) * perPage,
        }

        const res = await request<UsersResponse>(GET_USERS, variables)

        if (res?.users) {
          if (direction === 'asc') {
            await mutate(
              (prev) => {
                const prevUsers = prev?.users ?? []

                return {
                  users: [...prevUsers, ...res.users],
                }
              },
              { revalidate: false },
            )
          } else {
            await mutate(
              (prev) => {
                const prevUsers = prev?.users ?? []

                return {
                  users: [...res.users, ...prevUsers],
                }
              },
              { revalidate: false },
            )
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('fetchUsers error: ', JSON.stringify(error))
      }
    },
    [mutate, request],
  )

  const sortUsers: Context['sortUsers'] = useCallback(
    (sortBy) => {
      mutate(
        (prev) => ({ users: _.sortBy(prev?.users, sortBy === 'name' ? 'displayName' : sortBy) }),
        { revalidate: false },
        // eslint-disable-next-line no-console
      ).catch((error) => console.error('sortUsers error: ', error))
    },
    [mutate],
  )

  const searchUsers: Context['searchUsers'] = useCallback(
    (input) => {
      const variables = {
        offset: 0,
        limit: perPage,
        search: `${input}%`,
      }

      request<UsersResponse>(GET_USERS, variables)
        .then((res) => {
          if (res?.users) {
            mutate(
              () => ({
                users: [...res.users],
              }),
              { revalidate: false },
              // eslint-disable-next-line no-console
            ).catch((error) => console.error('searchUsers error: ', error))
          }
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error('searchUsers error', JSON.stringify(error)))
    },
    [mutate, request],
  )

  const value = useMemo(
    () => ({
      users: usersData?.users,
      mutate,
      perPage,
      sortUsers,
      totalUsers,
      fetchUsers,
      searchUsers,
    }),
    [fetchUsers, mutate, searchUsers, sortUsers, totalUsers, usersData?.users],
  )

  return <AdminUsersApiContext.Provider value={value}>{children}</AdminUsersApiContext.Provider>
}
