import { gql } from 'graphql-request'

export const GET_BRANCHES = gql`
  query Branches($limit: Int! = 10, $offset: Int! = 0, $search: citext = "%") {
    branches(limit: $limit, offset: $offset, where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`
