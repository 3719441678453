// libs
import _ from 'lodash'
import useSWR, { KeyedMutator } from 'swr'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'
// queries
import {
  GET_COMPANIES,
  CompaniesResponse,
  GET_COMPANIES_AGGREGATE,
  CompaniesAggregateResponse,
} from 'queries'
// types
import { Company } from 'types/hasura'
// providers
import { AppSwrConfigContext } from '../SwrConfig'

interface Props {
  children: ReactNode
}

interface Context {
  mutate: KeyedMutator<CompaniesResponse>
  perPage: number
  companies: Company[] | undefined
  sortCompanies: (sortBy: string) => void
  totalCompanies: number
  fetchCompanies: (page: number, direction: 'asc' | 'desc') => Promise<void>
  searchCompanies: (input: string) => void
}

export const AdminCompaniesApiContext = createContext<Context>({} as any)

export function AdminCompaniesApiProvider({ children }: Props): JSX.Element {
  const { request } = useContext(AppSwrConfigContext)

  const router = useRouter()

  const perPage = 10
  const initialPage = Number(router.query.page) || 1

  const { data } = useSWR<CompaniesAggregateResponse>(GET_COMPANIES_AGGREGATE, {
    revalidateOnFocus: false,
  })

  const { data: companiesData, mutate } = useSWR<CompaniesResponse>(
    [
      GET_COMPANIES,
      {
        limit: perPage,
        offset: (initialPage - 1) * perPage,
      },
    ],
    { revalidateOnFocus: false, revalidateIfStale: true },
  )

  const totalCompanies = data?.companiesAggregate?.aggregate?.count ?? 0

  const fetchCompanies: Context['fetchCompanies'] = useCallback(
    async (page, direction) => {
      try {
        const variables = {
          limit: perPage,
          offset: (page - 1) * perPage,
        }

        const res = await request<CompaniesResponse>(GET_COMPANIES, variables)

        if (res?.companies) {
          if (direction === 'asc') {
            await mutate(
              (prev) => {
                const prevCompanies = prev?.companies ?? []

                return {
                  companies: [...prevCompanies, ...res.companies],
                }
              },
              { revalidate: false },
            )
          } else {
            await mutate(
              (prev) => {
                const prevCompanies = prev?.companies ?? []

                return {
                  companies: [...res.companies, ...prevCompanies],
                }
              },
              { revalidate: false },
            )
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('fetchCompanies error: ', JSON.stringify(error))
      }
    },
    [mutate, request],
  )

  const sortCompanies: Context['sortCompanies'] = useCallback(
    (sortBy) => {
      mutate(
        (prev) => ({
          companies: _.sortBy(prev?.companies, sortBy),
        }),
        { revalidate: false },
        // eslint-disable-next-line no-console
      ).catch((error) => console.error('sortCompanies error: ', error))
    },
    [mutate],
  )

  const searchCompanies: Context['searchCompanies'] = useCallback(
    (input) => {
      const variables = {
        offset: 0,
        limit: perPage,
        search: `${input}%`,
      }

      request<CompaniesResponse>(GET_COMPANIES, variables)
        .then((res) => {
          if (res?.companies) {
            mutate(
              () => ({
                companies: [...res.companies],
              }),
              { revalidate: false },
              // eslint-disable-next-line no-console
            ).catch((error) => console.error('searchCompanies error: ', error))
          }
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error('searchCompanies error', JSON.stringify(error)))
    },
    [mutate, request],
  )

  const value = useMemo(
    () => ({
      mutate,
      perPage,
      companies: companiesData?.companies,
      sortCompanies,
      totalCompanies,
      fetchCompanies,
      searchCompanies,
    }),
    [
      companiesData?.companies,
      fetchCompanies,
      mutate,
      searchCompanies,
      sortCompanies,
      totalCompanies,
    ],
  )

  return (
    <AdminCompaniesApiContext.Provider value={value}>{children}</AdminCompaniesApiContext.Provider>
  )
}
