import { gql } from 'graphql-request'

export const GET_USERS = gql`
  query Users($limit: Int! = 10, $offset: Int! = 0, $search: String = "%") {
    users(limit: $limit, offset: $offset, where: { displayName: { _ilike: $search } }) {
      id
      displayName
      email
      createdAt
      defaultRole
      activeMfaType
      emailVerified
      metadata
      account {
        id
        integration {
          id
          name
        }
        company {
          id
          name
          settings
        }
        branch {
          id
          name
        }
      }
    }
  }
`

export const GET_USERS_AGGREGATE = gql`
  query UsersAggregate {
    usersAggregate {
      aggregate {
        count
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: uuid!
    $name: String
    $email: citext
    $emailVerified: Boolean
    $activeMfaType: String
    $defaultRole: String
  ) {
    updateUser(
      pk_columns: { id: $userId }
      _set: {
        email: $email
        displayName: $name
        emailVerified: $emailVerified
        activeMfaType: $activeMfaType
        defaultRole: $defaultRole
      }
    ) {
      id
      displayName
      email
      emailVerified
      activeMfaType
      defaultRole
    }
  }
`

export const UPDATE_USER_ACCOUNT = gql`
  mutation UpdateUserAccount($accountId: String!, $branchId: String, $companyId: String) {
    updateAccount(
      pk_columns: { id: $accountId }
      _set: { branchId: $branchId, companyId: $companyId }
    ) {
      branch {
        id
        name
      }
      company {
        id
        name
      }
    }
  }
`
