// views
import {
  IconWrapper,
  CLoseButton,
  ToasterTitle,
  ToasterMessage,
  ToasterContent,
  ToasterContainer,
} from './Toaster.views'

// icons
function CircleCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-green-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  )
}

function CircleXIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-red-600"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  )
}

function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  )
}

// types
interface ToasterProps {
  type: 'SUCCESS' | 'ERROR'
  title?: string
  visible: boolean
  dismiss: () => void
  message?: string
}

export function Toaster({ type, title, visible, dismiss, message }: ToasterProps): JSX.Element {
  return (
    <ToasterContainer $visible={visible}>
      <IconWrapper>{type === 'SUCCESS' ? <CircleCheckIcon /> : <CircleXIcon />}</IconWrapper>

      <ToasterContent>
        <ToasterTitle>{title}</ToasterTitle>
        {message && <ToasterMessage>{message}</ToasterMessage>}
      </ToasterContent>

      <CLoseButton onClick={dismiss}>
        <XIcon />
      </CLoseButton>
    </ToasterContainer>
  )
}
