import { tw } from 'packages/lib/tw'

export const ToasterContainer = tw.div<{ $visible: boolean }>` ${({ $visible }) =>
  $visible ? 'block' : 'hidden'}
    pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1
    ring-black ring-opacity-5 p-4 flex items-start
`

export const ToasterContent = tw.div`ml-3 w-0 flex-1 pt-0.5`

export const IconWrapper = tw.div`flex-shrink-0`

export const CLoseButton = tw.button`
  inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-0 focus:ring-offset-0
  ml-4 flex flex-shrink-0
`

export const ToasterTitle = tw.p`text-sm font-medium text-gray-900`

export const ToasterMessage = tw.p`mt-1 text-sm text-gray-500`
