import { gql } from 'graphql-request'

export const GET_COMPANIES = gql`
  query Companies($limit: Int! = 10, $offset: Int! = 0, $search: citext = "%") {
    companies(limit: $limit, offset: $offset, where: { name: { _ilike: $search } }) {
      id
      name
      domains
      settings
      updatedAt
      createdAt
      integration {
        id
        name
      }
      accounts_aggregate {
        aggregate {
          count
        }
      }
      accounts {
        user {
          id
          displayName
          email
          defaultRole
        }
      }
    }
  }
`

export const GET_COMPANIES_AGGREGATE = gql`
  query CompaniesAggregate {
    companiesAggregate {
      aggregate {
        count
      }
    }
  }
`

export const GET_COMPANY_USERS = gql`
  query CompanyUsers($companyId: String!, $limit: Int! = 10, $offset: Int! = 0) {
    users(limit: $limit, offset: $offset, where: { account: { companyId: { _eq: $companyId } } }) {
      id
      displayName
      email
      defaultRole
    }
  }
`

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($companyId: String!, $name: citext, $domains: jsonb, $settings: jsonb) {
    updateCompany(
      pk_columns: { id: $companyId }
      _set: { name: $name, domains: $domains, settings: $settings }
    ) {
      id
      name
      domains
      settings
    }
  }
`
