import { useToaster } from 'hooks/useToaster'
import cookie from 'js-cookie'
import { useEffect } from 'react'
import { AddToastProps, COOKIE_NAME } from './add-toast'

export function useGlobalToaster() {
  const { openToast } = useToaster()

  useEffect(() => {
    const cookieContent = cookie.get(COOKIE_NAME)
    const toasts = cookieContent ? JSON.parse(cookieContent) : []

    toasts.forEach((toast: AddToastProps) => openToast(toast))

    cookie.remove(COOKIE_NAME)
  }, [openToast])
}
