import { GetServerSidePropsContext, NextApiRequest, NextApiResponse, NextPageContext } from 'next'

export interface AddToastProps {
  type: 'SUCCESS' | 'ERROR'
  title?: string
  message?: string
}

const isServer = typeof window === 'undefined'

export const COOKIE_OPTIONS = {
  secure: process.env.NODE_ENV !== 'development',
  sameSite: 'strict',
  path: '/',
}

export const COOKIE_NAME = 'snapToasts'

export const COOKIE_EXPIRY = 60 * 60 * 24 * 7 // 7 days

export function addToast(
  toast: AddToastProps,
  context:
    | GetServerSidePropsContext
    | NextPageContext
    | { req: NextApiRequest; res: NextApiResponse }
    | undefined = undefined,
) {
  let cookie: any
  let toasts: any[] = []

  if (isServer) {
    // eslint-disable-next-line global-require
    cookie = require('cookie')
    toasts = cookie.parse(context?.req?.headers.cookie ?? '')[COOKIE_NAME] || []
  } else {
    // eslint-disable-next-line global-require
    cookie = require('js-cookie')
    toasts = cookie.get(COOKIE_NAME) || []
  }

  toasts = [...toasts, toast]

  if (isServer) {
    context?.res?.setHeader(
      'Set-Cookie',
      cookie.serialize(COOKIE_NAME, JSON.stringify(toasts), {
        ...COOKIE_OPTIONS,
        maxAge: COOKIE_EXPIRY,
      }),
    )
  } else {
    cookie.set(COOKIE_NAME, JSON.stringify(toasts), { ...COOKIE_OPTIONS, expires: COOKIE_EXPIRY })
  }
}
