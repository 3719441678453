// libs
import { SWRConfig } from 'swr'
import { useAccessToken } from '@nhost/nextjs'
import { createContext, ReactNode, useCallback, useMemo } from 'react'
import { request as graphqlRequest, RequestDocument } from 'graphql-request'

// types
interface Props {
  children: ReactNode
}

interface SwrConfigContext {
  request: <T extends object>(
    query: RequestDocument,
    variables?: Record<string, unknown>,
  ) => Promise<T>
}

export const AppSwrConfigContext = createContext<SwrConfigContext>({} as any)

export function SwrConfigProvider({ children }: Props): JSX.Element {
  const accessToken = useAccessToken()

  /* eslint-disable @typescript-eslint/naming-convention */
  const headers = useMemo(
    () => ({
      'Content-Type': 'application/json',
      ...(Boolean(accessToken) && { Authorization: `Bearer ${accessToken}` }),
    }),
    [accessToken],
  )
  /* eslint-enable @typescript-eslint/naming-convention */

  const request: SwrConfigContext['request'] = useCallback(
    (query, variables) =>
      graphqlRequest(process.env.NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT, query, variables, headers),
    [headers],
  )

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppSwrConfigContext.Provider value={{ request }}>
      <SWRConfig value={{ fetcher: request }}>{children}</SWRConfig>
    </AppSwrConfigContext.Provider>
  )
}
