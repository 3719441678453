import { gql } from 'graphql-request'

export const GET_INTEGRATIONS = gql`
  query Integrations($limit: Int! = 10, $offset: Int! = 0, $search: citext = "%") {
    integrations(limit: $limit, offset: $offset, where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`
