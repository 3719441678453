// libs
import { useState, createContext, ReactNode, useMemo, useCallback } from 'react'

// types
interface Props {
  children: ReactNode
}

interface Context {
  select: (item: Record<string, unknown>) => void
  selected: Record<string, unknown> | null
  resetSelection: () => void
}

export const AdminPanelContext = createContext<Context>({} as any)

export function AdminPanelContextProvider({ children }: Props): JSX.Element {
  const [selected, setSelected] = useState<Record<string, unknown> | null>(null)

  const resetSelection = () => setSelected(null)

  const selectItem = useCallback(
    (item: Record<string, unknown>) => {
      if (item.id === selected?.id) {
        resetSelection()
        return
      }

      setSelected(item)
    },
    [selected?.id],
  )

  const value = useMemo(
    () => ({
      selected,
      resetSelection,
      select: selectItem,
    }),
    [selectItem, selected],
  )

  return <AdminPanelContext.Provider value={value}>{children}</AdminPanelContext.Provider>
}
