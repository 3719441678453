/* eslint-disable @typescript-eslint/naming-convention */
const hoistNonReactStatics = require('hoist-non-react-statics')

module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['common'],
    '/sign-up': ['sign-up'],
    '/sign-in': ['sign-in'],
    '/sign-in/passwordless': ['passwordless-login'],
    '/login': ['sign-in'],
    '/login/passwordless': ['passwordless-login'],
    '/verify-email': ['verify-email'],
    '/reset-password': ['reset-password'],
    '/reset-password/confirm': ['reset-password-confirm'],
    '/sign-in/tfa/configure': ['tfa-configure'],
    '/sign-in/tfa/verify': ['tfa-verify'],
  },
  staticsHoc: hoistNonReactStatics,
  localeDetection: false,
}
