import * as React from 'react'
      export default ([React.createElement("link", {"rel":"shortcut icon","href":"/_next/static/favicons/favicon.ico","key":"0"}),
React.createElement("link", {"rel":"icon","type":"image/png","sizes":"16x16","href":"/_next/static/favicons/favicon-16x16.png","key":"1"}),
React.createElement("link", {"rel":"icon","type":"image/png","sizes":"32x32","href":"/_next/static/favicons/favicon-32x32.png","key":"2"}),
React.createElement("link", {"rel":"icon","type":"image/png","sizes":"48x48","href":"/_next/static/favicons/favicon-48x48.png","key":"3"}),
React.createElement("link", {"rel":"manifest","href":"/_next/static/favicons/manifest.json","key":"4"}),
React.createElement("meta", {"name":"mobile-web-app-capable","content":"yes","key":"5"}),
React.createElement("meta", {"name":"theme-color","content":"#142852","key":"6"}),
React.createElement("meta", {"name":"application-name","content":"Snap","key":"7"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"57x57","href":"/_next/static/favicons/apple-touch-icon-57x57.png","key":"8"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"60x60","href":"/_next/static/favicons/apple-touch-icon-60x60.png","key":"9"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"72x72","href":"/_next/static/favicons/apple-touch-icon-72x72.png","key":"10"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"76x76","href":"/_next/static/favicons/apple-touch-icon-76x76.png","key":"11"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"114x114","href":"/_next/static/favicons/apple-touch-icon-114x114.png","key":"12"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"120x120","href":"/_next/static/favicons/apple-touch-icon-120x120.png","key":"13"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"144x144","href":"/_next/static/favicons/apple-touch-icon-144x144.png","key":"14"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"152x152","href":"/_next/static/favicons/apple-touch-icon-152x152.png","key":"15"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"167x167","href":"/_next/static/favicons/apple-touch-icon-167x167.png","key":"16"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"180x180","href":"/_next/static/favicons/apple-touch-icon-180x180.png","key":"17"}),
React.createElement("link", {"rel":"apple-touch-icon","sizes":"1024x1024","href":"/_next/static/favicons/apple-touch-icon-1024x1024.png","key":"18"}),
React.createElement("meta", {"name":"apple-mobile-web-app-capable","content":"yes","key":"19"}),
React.createElement("meta", {"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent","key":"20"}),
React.createElement("meta", {"name":"apple-mobile-web-app-title","content":"Snap","key":"21"})])
      