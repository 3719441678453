// libs
import capitalize from 'lodash/capitalize'
import { toast } from 'react-hot-toast'
// components
import { Toaster } from 'ui/Toaster'

// types
interface OpenToastParam {
  type: 'SUCCESS' | 'ERROR'
  title?: string
  message?: string
}

// TODO: This needs to use i18n
function defaultMesage(type: OpenToastParam['type']) {
  switch (type) {
    case 'ERROR':
      return 'Oops... Something went wrong!'
    default:
      return ''
  }
}

export const useToaster = () => {
  const openToast = (data: OpenToastParam) => {
    toast.dismiss()

    const title = data.title || capitalize(data.type)
    const message = data.message || defaultMesage(data.type)

    toast.custom((t) => (
      <Toaster
        type={data.type}
        visible={t.visible}
        message={message}
        title={title}
        dismiss={() => toast.dismiss(t.id)}
      />
    ))
  }

  return { openToast }
}
