export * from './tw'

export function textSizes({ size = 'base' }) {
  return {
    xs: 'text-sm',
    sm: 'text-base',
    base: 'text-xl',
    lg: 'text-2xl',
    xl: 'text-3xl',
  }[size]
}
