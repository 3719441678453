
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
// libs
import { withTRPC } from '@trpc/next'
import superjson from 'superjson'
import { nhost } from '@lib/nhost'
import { Router } from 'next/router'
import { Toaster } from 'react-hot-toast'
import NProgress from 'nprogress'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { ReactNode } from 'react'
import { useGlobalToaster } from '@lib/toast/use-global-toaster'
import { NhostNextProvider } from '@nhost/nextjs'
// components
// eslint-disable-next-line import/extensions
import Meta from 'public/favicon'
import Head from 'next/head'
// styles
import 'styles/globals.css'
import 'nprogress/nprogress.css'
// providers
import { SwrConfigProvider } from 'providers'
import { AppRouter } from 'server/routers/app'
import { AppType } from 'next/dist/shared/lib/utils'

NProgress.configure({ showSpinner: false, speed: 500 })
Router.events.on('routeChangeError', () => NProgress.done())
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())

function getBaseUrl() {
  if (typeof window !== 'undefined') {
    return ''
  }
  // reference for vercel.com
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`
  }

  // reference for render.com
  if (process.env.RENDER_INTERNAL_HOSTNAME) {
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}

// types
export interface NextPageWithLayoutProps {
  children: JSX.Element
}

export type NextPageWithLayout = NextPage & {
  getLayout?: ({ children }: NextPageWithLayoutProps) => JSX.Element
}

type AppPropsWithLayout = AppProps & {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: NextPageWithLayout
  err: any
}

function App({ Component, pageProps, err }: AppPropsWithLayout): ReactNode {
  // Use the layout defined at the page level, if available
  const Layout = Component.getLayout ?? (({ children }) => children)

  useGlobalToaster()

  return (
    <>
      <Head>{Meta}</Head>
      <NhostNextProvider nhost={nhost} initial={pageProps?.nhostSession}>
        <SwrConfigProvider>
          <Layout>
            <Component {...pageProps} err={err} />
          </Layout>
        </SwrConfigProvider>
      </NhostNextProvider>
      <Toaster />
    </>
  )
}

const __Page_Next_Translate__ = withTRPC<AppRouter>({
  // { ctx }) {
  config() {
    if (typeof window !== 'undefined') {
      // during client requests
      return {
        transformer: superjson, // optional - adds superjson serialization
        url: '/api/trpc',
      }
    }

    // optional: use SSG-caching for each rendered page (see caching section for more details)
    // const ONE_DAY_SECONDS = 60 * 60 * 24
    // ctx?.res?.setHeader('Cache-Control', `s-maxage=1, stale-while-revalidate=${ONE_DAY_SECONDS}`)
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      transformer: superjson,

      url: `${getBaseUrl()}/api/trpc`,

      headers: {
        // optional - inform server that it's an ssr request
        // eslint-disable-next-line @typescript-eslint/naming-convention
        // 'x-ssr': '1',
      },
      /**
       * @link https://react-query-v3.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  // NOTE:ssr is causing issues currently https://github.com/trpc/trpc/issues/596
  // for now it's best to leave it off. If you need to pre-render a query please follow:
  // https://trpc.io/docs/ssg-helpers
  ssr: false,
})(App as AppType)


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  