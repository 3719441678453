/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: Make this its own library, come up with cool name.
import React, { ComponentType, ReactNode, useMemo } from 'react'

import pickBy from 'ramda/src/pickBy'
import htmlTags from 'html-tags'
import svgTags from 'svg-tags'

export type TwUiSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl'

type TwOptions = {
  displayName?: string
  ['data-testid']?: string
} & React.AllHTMLAttributes<any>

export interface TwProps {
  className?: string
  children?: ReactNode
}

export type TwComponent<T = object> = ComponentType<T & TwProps> | keyof JSX.IntrinsicElements

type TransientProps<T = object> = Record<`$${string}`, T> | T

export type FunctionTemplate<P, E> = <K extends TransientProps = {}>(
  template: TemplateStringsArray,
  ...templateElements: ((props: P & K) => string | undefined | null)[]
) => React.ForwardRefExoticComponent<React.PropsWithoutRef<P & K> & React.RefAttributes<E>>

export function twRaw<T, NestedComponents = {}>(Component: TwComponent<T>, options?: TwOptions) {
  type TwComponentProps = T & TwProps
  type Expression = (...props: TwComponentProps[]) => string

  const displayName =
    (options || {}).displayName ??
    (typeof Component === 'string' ? `tw.${Component}` : Component.displayName)

  return function createComponent(strings: TemplateStringsArray, ...expressions: Expression[]) {
    const classNameStrings = Array.isArray(strings) ? strings.map((string) => string) : []

    function TwComponent(componentProps: TwComponentProps, ref: any) {
      const props = useMemo(() => ({ ...(options || {}), ...componentProps }), [componentProps])

      const className = useMemo(() => {
        const mappedExpressions = expressions.map((expression) => expression(props) || '')

        return [...classNameStrings, ...mappedExpressions, props.className || '']
          .join(' ') // We want the output to be as clean as possible
          .replace(/\s\s+/g, ' ')
          .trim()
      }, [props])

      const filteredProps: TwComponentProps = useMemo(
        () =>
          pickBy(
            (_value: any, key: any): boolean => key.charAt(0) !== '$' && key !== 'displayName',
            props,
          ),
        [props],
      )

      return React.createElement(
        Component,
        {
          ref,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'data-testid': displayName,
          ...filteredProps,
          className,
        },
        props.children,
      )
    }

    TwComponent.displayName = `tw.${displayName}`

    const TwComponentForwardRef = React.forwardRef<TwComponent<T>, TwComponentProps>(TwComponent)

    TwComponentForwardRef.displayName = displayName

    return TwComponentForwardRef as typeof TwComponentForwardRef & NestedComponents
  }
}

export const domElements = [...htmlTags, ...svgTags]

export type IntrinsicElements = {
  [key in keyof JSX.IntrinsicElements]: FunctionTemplate<JSX.IntrinsicElements[key], void>
}

// @ts-ignore
const intrinsicElements: IntrinsicElements = domElements.reduce(
  // @ts-ignore
  <K extends keyof JSX.IntrinsicElements>(acc: IntrinsicElements, DomElement: K) => ({
    ...acc,
    [DomElement]: twRaw(DomElement as unknown as React.ComponentType<JSX.IntrinsicElements[K]>),
  }),
  {} as IntrinsicElements,
)

const tw = Object.assign(twRaw, intrinsicElements)

export { tw }
